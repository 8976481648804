import React from 'react';
import Layout from '../../components/Layout';
import { withIntl } from '../../i18n';
import FormPage from '../../templates/FormPage';

const host = process.env.GATSBY_HOST;

const formWorkshop = {
  title: 'workshopsTitle',
  subTitle: 'workshopsSubTitle',
  form: {
    title: 'Contact us',
    type: 'workshop',
    fields: [
      {
        type: 'firstName',
        label: 'formFirstName',
        value: ''
      },
      {
        type: 'lastName',
        label: 'formLastName',
        value: ''
      },
      {
        type: 'email',
        label: 'formEmail',
        value: ''
      },
      {
        type: 'company',
        label: 'formCompany',
        value: ''
      },
      {
        type: 'employees',
        label: 'formEmployees',
        value: ''
      },
      // {
      //   type: 'format',
      //   label: 'formFormat',
      //   value: '',
      //   list: [
      //     {
      //       type: 'online',
      //       value: 'formFormatOnline',
      //     },
      //     {
      //       type: 'offline',
      //       value: 'formFormatOffline',
      //     }
      //   ]
      // },
      {
        type: 'message',
        label: 'formMessage',
        value: ''
      },
    ],
    button: {
      text: 'formSend',
      link: `${host}/api/1/json/public/513644/6d85995d468d2cd7d26930393473dff049842412`,
    }
  }
};


class FormWorkshop extends React.PureComponent {
  render() {
    return (
      <Layout>
        <FormPage data={formWorkshop} />
      </Layout>
    );
  }
}

export default withIntl(FormWorkshop);

